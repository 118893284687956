#and6-filters {
  margin-top: 20px;
  min-height: 50px;
}
#and6-filters.gps-location {
  min-height: 72px;
}
#and6-filters.gps-location .filter-header-lg {
  display: none !important;
}
#and6-filters.gps-location .filter-header-sm {
  display: grid !important;
  grid-template-columns: 1fr;
  grid-template-rows: unset;
}
#and6-filters.gps-location .filter-header-sm > *:not(.gps-location-bt) {
  display: none;
}
#and6-filters.gps-location .gps-location-btn {
  display: flex !important;
}
@media (max-width: 768px) {
  #and6-filters {
    min-height: 132px;
  }
}

.filter-container {
  overflow: hidden;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.filter-container .filter-header-lg {
  display: flex;
  justify-content: space-between;
  grid-gap: 10px;
  height: 54px;
  padding: 10px;
  background-color: #f0f0f0;
}
.filter-container .filter-header-lg .left-side {
  display: flex;
}
.filter-container .filter-header-lg .left-side button {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  justify-content: space-between;
  width: 120px;
  margin-right: 10px;
  overflow: hidden;
  height: 34px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  font-size: calc(13px + var(--big-fs));
  line-height: 18px;
}
.filter-container .filter-header-lg .left-side .top-filter-group {
  display: flex;
  grid-gap: 10px;
  align-items: center;
  width: fit-content;
}
.filter-container .filter-header-lg .right-side {
  display: flex;
  grid-gap: 10px;
}
.filter-container .filter-header-lg .right-side select {
  width: 180px;
}
.filter-container .filter-header-sm {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: repeat(2, 42px);
  grid-gap: 16px;
  width: 100%;
  background-color: #f0f0f0;
  padding: 16px;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.filter-container .filter-header-sm > * {
  overflow: hidden;
}
.filter-container .filter-header-sm .search-control {
  width: 100%;
  grid-column: 1/-1;
}
.filter-container .filter-header-sm .search-control-icon i {
  width: 16px;
  height: 16px;
}
.filter-container .filter-header-sm .area-control {
  width: 100%;
  grid-column: 1/-1;
}
.filter-container .filter-header-sm .area-control .select2-selection {
  padding-right: 16px;
}
.filter-container .filter-header-sm .sort {
  display: flex;
}
.filter-container .filter-list {
  display: grid;
  grid-gap: 10px;
  justify-content: flex-start;
  border: 2px solid #f0f0f0;
  border-top: 0;
  overflow: hidden;
  max-height: 0;
  transition: all 0.4s ease-in-out;
}
.filter-container .filter-list .filter-group {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  padding: 10px 8px;
}
.filter-container .filter-list .filter-group a {
  width: fit-content;
}
.filter-container .filter-list .filter-group h4.title {
  font-weight: 600;
  margin-bottom: 10px;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.filter-container .filter-list .filter-group ul {
  display: grid;
  grid-gap: 10px;
  padding: 0;
  margin: 0;
}
.filter-container .filter-list .filter-group ul li {
  padding-right: 16px;
}
.filter-container .filter-list > *:last-child {
  margin-bottom: 16px;
}
.filter-container .search-control {
  width: 216px;
  margin-right: 20px;
  position: relative;
}
.filter-container .search-control input {
  padding-right: 40px !important;
}
.filter-container .search-control-icon {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  color: #949496;
  padding: 0 16px;
  pointer-events: none;
}
.filter-container .search-control-icon i {
  width: 12px;
  height: 12px;
}
.filter-container .show-txt,
.filter-container .hide-txt {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: 8px;
}
.filter-container:not(.open) .hide-txt {
  display: none;
}
.filter-container.open .show-txt {
  display: none;
}
.filter-container.open .filter-list {
  max-height: 800px;
  transition: all 0.4s ease-in-out;
}
@media (max-width: 1024px) {
  .filter-container {
    margin-left: -16px;
    margin-right: -16px;
  }
}

.sc-dialog-form {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  overflow: auto;
}
@media (max-width: 768px) {
  .sc-dialog-form {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}

#sc-dialog-daily-filter .modal-header,
.sc-dialog-others-form .modal-header {
  padding-bottom: 18px;
}
#sc-dialog-daily-filter .accordion,
.sc-dialog-others-form .accordion {
  overflow-x: hidden;
}
#sc-dialog-daily-filter .accordion-item,
.sc-dialog-others-form .accordion-item {
  border-width: 0 0 1px 0;
}
#sc-dialog-daily-filter .accordion-item .accordion-header,
.sc-dialog-others-form .accordion-item .accordion-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: fit-content;
  padding: 12px 0;
  font-weight: bold;
  color: #d61f79;
  cursor: pointer;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
#sc-dialog-daily-filter .accordion-item .accordion-header i,
.sc-dialog-others-form .accordion-item .accordion-header i {
  color: #000000;
  transform: rotate(270deg);
  transition: transform 0.3s linear;
}
#sc-dialog-daily-filter .accordion-item .accordion-header.collapsed i,
.sc-dialog-others-form .accordion-item .accordion-header.collapsed i {
  transform: rotate(90deg);
}
#sc-dialog-daily-filter .accordion-item .accordion-body,
.sc-dialog-others-form .accordion-item .accordion-body {
  padding: 0;
}
#sc-dialog-daily-filter .accordion-item .accordion-body ul,
.sc-dialog-others-form .accordion-item .accordion-body ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  overflow: auto;
  margin-bottom: 16px;
}
@media (max-width: 768px) {
  #sc-dialog-daily-filter .accordion-item .accordion-body ul,
  .sc-dialog-others-form .accordion-item .accordion-body ul {
    grid-gap: 20px;
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 768px) {
  #sc-dialog-daily-filter .accordion,
  .sc-dialog-others-form .accordion {
    margin-left: -20px;
    margin-right: -20px;
  }
  #sc-dialog-daily-filter .accordion .accordion-item,
  .sc-dialog-others-form .accordion .accordion-item {
    padding-left: 20px;
    padding-right: 20px;
  }
  #sc-dialog-daily-filter .accordion .accordion-item .accordion-header,
  .sc-dialog-others-form .accordion .accordion-item .accordion-header {
    padding: 20px 0;
    font-size: calc(16px + var(--big-fs));
    line-height: 22px;
  }
}

@media (max-width: 768px) {
  .sc-dialog-others-form {
    position: relative;
    top: -20px;
  }
}

.viewed-count {
  display: flex;
  gap: 3px;
  padding: 3px 4px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  width: fit-content;
  border-radius: 2px;
}
.viewed-count .count {
  font-weight: bold;
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}
.viewed-count.transparent {
  background-color: transparent;
  color: #949496;
}

.xl-view .e-item-image .title-box span {
  justify-content: center;
  padding-right: 15px;
}

.e-image {
  display: flex;
  position: relative;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
}
.e-image a,
.e-image img {
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.e-image .play-icon {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  color: #d61f79;
  background-color: #ffffff;
}

.e-item-image {
  position: relative;
  height: fit-content;
  border: 2px solid transparent;
  border-radius: 4px;
  overflow: hidden;
}
.e-item-image .avatar {
  display: flex;
  position: relative;
  padding-top: calc(133.3333333333% - 8px);
  overflow: hidden;
  width: 100%;
  border-radius: 4px;
}
.e-item-image .avatar > img {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.e-item-image .title-box {
  border-radius: 2px;
  left: 9px;
  padding: 0 5px;
  pointer-events: none;
  position: absolute;
  right: 9px;
  top: 9px;
  width: auto;
  z-index: 1;
  min-height: 26px;
  height: fit-content;
  display: flex;
  align-items: flex-end;
}
.e-item-image .title-box img {
  width: 31px;
  height: 24px;
  margin-right: 8px;
}
.e-item-image .title-box span {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  min-height: 26px;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.e-item-image .top-area,
.e-item-image .bottom-area {
  position: absolute;
  left: 2px;
  right: 2px;
  width: auto;
  display: flex;
  justify-content: space-between;
  grid-gap: 16px;
}
.e-item-image .top-area .left-side,
.e-item-image .top-area .right-side,
.e-item-image .bottom-area .left-side,
.e-item-image .bottom-area .right-side {
  display: flex;
  align-items: center;
  grid-gap: 2px;
}
.e-item-image .top-area {
  top: 2px;
}
.e-item-image .top-area .happy-hour {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 6px;
  padding: 3px;
  color: #ffd514;
  border-radius: 8px;
  background-color: rgba(0, 0, 0, 0.5);
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}
.e-item-image .bottom-area {
  bottom: 2px;
}
.e-item-image .bottom-area .icon-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 2px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.5);
}
.e-item-image .bottom-area .video-icon {
  background-color: #d71f7a;
}
.e-item-image .bottom-area .comment-icon {
  background-color: #28a445;
}
.e-item-image .bottom-area .verified-icon {
  color: #1d9bf0;
  font-size: calc(22px + var(--big-fs));
  line-height: 1;
}
.e-item-image .bottom-area .viewed-count {
  display: flex;
  gap: 3px;
  padding: 3px 4px;
  color: #ffffff;
  background-color: rgba(0, 0, 0, 0.3);
  width: fit-content;
}
.e-item-image .bottom-area .viewed-count .count {
  font-weight: bold;
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}
.e-item-image .bottom-area .online-now {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 3px;
  min-width: 69px;
  max-width: 100px;
  height: 22px;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
  text-transform: lowercase;
  border: 1px solid #02b10e;
  background-color: #ffffff;
  color: #02b10e;
  font-size: calc(10px + var(--big-fs));
  line-height: 14px;
}
.e-item-image .new-icon {
  position: absolute;
  top: 0;
  left: 0;
}
.e-item-image .new-icon .txt {
  display: block;
  transform: rotate(-45deg);
  color: #ffffff;
  font-weight: bold;
  text-transform: uppercase;
  position: relative;
  width: 54px;
  margin: 0;
  padding: 0;
  top: -41px;
  left: -6px;
  text-align: center;
  font-size: calc(12px + var(--big-fs));
  line-height: 16px;
}
.e-item-image .content {
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: flex-end;
  grid-gap: 16px;
  padding: 2px 2px 4px 8px;
}
.e-item-image .content .info {
  display: grid;
  grid-gap: 2px;
}
.e-item-image .content .info a {
  width: fit-content;
}
.e-item-image-standart {
  border: none;
}
.e-item-image-standart .avatar {
  border: 1px solid #d61f79;
}
.e-item-image-standart .content {
  grid-template-columns: 1fr;
  align-items: flex-start;
  padding: 2px 0 0;
}
.e-item-image-standart .content img {
  display: none;
}
.e-item-image-selected .content {
  min-height: 64px;
  margin-top: 2px;
  align-items: center;
  padding: 2px 2px 2px 8px;
}
.e-item-image-selected .content .info {
  align-items: flex-start;
  height: auto;
}
.e-item-image-selected .content img {
  margin-right: 7px;
}

.escort-item.theme-1 .e-item-image-selected {
  background-color: #ffd514;
}
.escort-item.theme-1 .e-item-image-selected .title-box {
  color: #000000;
  background-color: rgba(255, 213, 20, 0.8);
}
.escort-item.theme-2 .e-item-image-selected {
  color: #ffffff;
  background-color: #d61f79;
}
.escort-item.theme-2 .title-box {
  color: #ffffff;
  background-color: rgba(214, 31, 121, 0.8);
}
.escort-item.theme-2 .content .info a {
  color: #ffffff;
}
.escort-item.theme-2 .content .info a:hover {
  color: #f2f2f2;
}
.escort-item.theme-2 .content .info a:active {
  color: #e6e6e6;
}
.escort-item .tour {
  font-weight: 400;
  color: #949496;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.escort-item .tour:first-line {
  color: #000000;
}

.main-listing-container {
  display: flex;
  flex-direction: column;
}
.main-listing-container .escorts-list {
  display: grid;
  grid-gap: 10px;
}
.main-listing-container .escorts-list .no-escort {
  grid-column: 1/-1;
  padding: 0 0 20px;
}
@media (max-width: 768px) {
  .main-listing-container .escorts-list .no-escort {
    padding: 0 12px 20px;
  }
}
.main-listing-container .escorts-list .banner-area a {
  display: block;
  width: 100%;
  height: fit-content;
}
.main-listing-container .escorts-list .banner-area a img {
  width: 100%;
  height: auto;
  max-height: 100%;
  object-fit: cover;
}
.main-listing-container .escorts-list .banner-area .visible-banner-sm {
  display: none;
}
.main-listing-container .listing-double-banner {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  grid-column: 1/-1;
}
.main-listing-container .listing-double-banner.line-8 {
  grid-row-start: 8;
}
.main-listing-container .listing-double-banner.line-7 {
  grid-row-start: 7;
}
.main-listing-container .loader.loader-active {
  position: relative;
  height: 40px;
}
.main-listing-container.xl-view .escorts-list {
  grid-gap: 20px;
  grid-template-columns: repeat(3, 1fr);
}
.main-listing-container.xl-view .escorts-list .escort-item-images .e-item-image:not(:first-child) {
  display: none;
}
.main-listing-container.xl-view .escorts-list .escort-item-content {
  display: none;
}
.main-listing-container.xl-view .escorts-list .escort-item .content .info .fs-12 {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.main-listing-container.xl-view .escorts-list .banner-area {
  grid-row-start: 2;
  grid-column: 1/-1;
}
.main-listing-container.xl-view .escorts-list .banner-area .grid-banner {
  display: none;
}
@media (max-width: 768px) {
  .main-listing-container.xl-view .escorts-list {
    grid-gap: 15px 5px;
    grid-template-columns: repeat(3, 1fr);
  }
}
.main-listing-container.grid-view .escorts-list {
  grid-gap: 15px;
  grid-template-columns: repeat(5, 1fr);
}
.main-listing-container.grid-view .escorts-list .escort-item-images .e-item-image:not(:first-child) {
  display: none;
}
.main-listing-container.grid-view .escorts-list .escort-item-content {
  display: none;
}
.main-listing-container.grid-view .escorts-list .banner-area {
  grid-row-start: 2;
  grid-column-end: -2;
  grid-column-start: 2;
}
.main-listing-container.grid-view .escorts-list .banner-area .list-banner {
  display: none;
}
@media (max-width: 1024px) {
  .main-listing-container.grid-view .escorts-list {
    grid-template-columns: repeat(4, 1fr);
  }
  .main-listing-container.grid-view .escorts-list .banner-area {
    grid-row-start: 2;
    grid-column: 1/-1;
  }
}
@media (max-width: 768px) {
  .main-listing-container.grid-view .escorts-list {
    grid-gap: 15px 5px;
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 480px) {
  .main-listing-container.grid-view .escorts-list {
    grid-template-columns: 1fr 1fr;
  }
  .main-listing-container.grid-view .escorts-list .hidden-banner-sm {
    display: none;
  }
  .main-listing-container.grid-view .escorts-list .banner-area .visible-banner-sm {
    display: block !important;
  }
}
.main-listing-container.list-view .escorts-list {
  grid-gap: 10px;
  grid-template-columns: 1fr;
}
.main-listing-container.list-view .escorts-list .escort-item {
  display: grid;
  background: #f0f0f0;
  padding: 10px;
  grid-gap: 20px;
  border-radius: 4px;
  grid-template-columns: 308px 1fr;
}
.main-listing-container.list-view .escorts-list .escort-item .tour {
  display: flex;
  flex-basis: 100%;
  grid-gap: 4px;
  flex-wrap: wrap;
}
.main-listing-container.list-view .escorts-list .escort-item .tour.city-page {
  color: #000000;
}
.main-listing-container.list-view .escorts-list .escort-item .tour.city-page br {
  display: none;
}
.main-listing-container.list-view .escorts-list .escort-item.selected .escort-item-images {
  grid-template-columns: 180px 1fr;
}
.main-listing-container.list-view .escorts-list .escort-item.standart .escort-item-images {
  grid-template-columns: 200px 1fr;
}
.main-listing-container.list-view .escorts-list .escort-item-images {
  display: grid;
  grid-template-columns: 180px 1fr;
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px 12px;
}
.main-listing-container.list-view .escorts-list .escort-item-images .e-item-image .viewed-count {
  display: none;
}
.main-listing-container.list-view .escorts-list .escort-item-images .e-item-image-standart .content {
  display: none;
}
.main-listing-container.list-view .escorts-list .escort-item-images .e-item-image:first-child {
  grid-row-start: 1;
  grid-row-end: 3;
  grid-column-start: 1;
}
.main-listing-container.list-view .escorts-list .escort-item-content {
  display: grid;
  height: fit-content;
}
.main-listing-container.list-view .escorts-list .escort-item-content > div:not(:last-of-type) {
  border-bottom: 1px solid #d4d4d4;
}
.main-listing-container.list-view .escorts-list .escort-item-content .top-section {
  display: grid;
  grid-template-columns: auto auto;
  align-items: center;
  padding: 4px 0 12px;
  min-height: 40px;
  box-sizing: border-box;
}
.main-listing-container.list-view .escorts-list .escort-item-content .top-section .showname {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  grid-column-start: 1;
  font-weight: bold;
  flex-wrap: wrap;
}
.main-listing-container.list-view .escorts-list .escort-item-content .top-section .showname .add-text {
  flex: 1 1 auto;
  width: 100%;
}
@media (max-width: 1024px) {
  .main-listing-container.list-view .escorts-list .escort-item-content .top-section .showname {
    align-items: flex-start;
  }
}
.main-listing-container.list-view .escorts-list .escort-item-content .top-section .top-right {
  display: flex;
  align-items: center;
  gap: 10px;
  grid-column-start: -1;
  padding-top: 6px;
}
.main-listing-container.list-view .escorts-list .escort-item-content .top-section .viewed-count {
  justify-content: flex-end;
  margin-left: auto;
}
.main-listing-container.list-view .escorts-list .escort-item-content .top-section .verified {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.main-listing-container.list-view .escorts-list .escort-item-content .top-section .verified i {
  display: flex;
  align-items: center;
  width: 22px;
  height: 22px;
}
.main-listing-container.list-view .escorts-list .escort-item-content .mid-section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0;
  grid-gap: 16px;
}
.main-listing-container.list-view .escorts-list .escort-item-content .mid-section .phone {
  display: flex;
  align-items: center;
  justify-content: center;
}
.main-listing-container.list-view .escorts-list .escort-item-content .mid-section .phone-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 10px;
  width: 34px;
  height: 34px;
  background-color: #ffffff;
  color: #d61f79;
}
.main-listing-container.list-view .escorts-list .escort-item-content .mid-section .phone-call {
  display: grid;
}
.main-listing-container.list-view .escorts-list .escort-item-content .mid-section .actions {
  display: flex;
  grid-gap: 10px;
}
.main-listing-container.list-view .escorts-list .escort-item-content .mid-section .actions span {
  display: flex;
  align-items: center;
}
.main-listing-container.list-view .escorts-list .escort-item-content .bottom-section {
  padding-top: 12px;
  overflow: hidden;
}
.main-listing-container.list-view .escorts-list .escort-item.theme-1 {
  border: 2px solid #ffd514;
}
.main-listing-container.list-view .escorts-list .escort-item.theme-2 {
  border: 2px solid #d61f79;
}
@media (max-width: 1024px) {
  .main-listing-container.list-view .escorts-list .escort-item-content {
    display: grid;
    align-items: flex-start;
    height: 100%;
    grid-template-rows: min-content;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .line-5 {
    -webkit-line-clamp: 5 !important;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .gps-position {
    padding-top: 10px;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content > div:not(:last-child) {
    border-bottom: none;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .top-section {
    padding: 0;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .top-section .showname {
    flex-direction: column;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .top-section .showname span {
    margin-left: 0 !important;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .bottom-section {
    padding: 0;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .bottom-section.mbt-10 {
    margin-top: 10px;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .bottom-section p {
    -webkit-line-clamp: 7;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content .bottom-section p.line-6 {
    -webkit-line-clamp: 6;
  }
}
@media (max-width: 768px) {
  .main-listing-container.list-view .escorts-list .escort-item {
    padding: 0;
    border: none !important;
    background: none;
    grid-gap: 10px;
    grid-template-columns: 180px 1fr;
  }
  .main-listing-container.list-view .escorts-list .escort-item-images {
    grid-template-columns: 1fr !important;
  }
  .main-listing-container.list-view .escorts-list .escort-item-content {
    padding: 10px 0;
  }
}
.main-listing-container.list-view .escorts-list .banner-area {
  grid-row-start: 3;
  grid-column: 1/-1;
}
.main-listing-container.list-view .escorts-list .banner-area .grid-banner {
  display: none;
}
@media (max-width: 480px) {
  .main-listing-container.list-view .escorts-list .hidden-banner-sm {
    display: none;
  }
  .main-listing-container.list-view .escorts-list .banner-area .visible-banner-sm {
    display: block !important;
  }
}
@media (max-width: 768px) {
  .main-listing-container {
    margin-left: -11px;
    margin-right: -11px;
  }
}

.sc-geo-areas {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px 10px;
  margin: 20px 0 0;
  padding: 0;
}
.sc-geo-areas li {
  display: flex;
  justify-content: flex-start;
  width: fit-content;
  grid-gap: 4px;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
.sc-geo-areas li a {
  font-family: Arial, Helvetica, sans-serif !important;
  letter-spacing: 0.02em !important;
  font-weight: 400 !important;
}
.sc-geo-areas li a:hover {
  text-decoration: underline;
}
@media (max-width: 768px) {
  .sc-geo-areas {
    grid-template-columns: repeat(2, 1fr);
    margin: 20px 0 40px;
    grid-gap: 14px;
  }
}

main .sc-container-fluid .popular-cities {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  color: #000000;
  padding: 20px 0;
  border: 1px solid #f0f0f0;
  border-left: 0;
  border-right: 0;
  margin: 20px 0;
  grid-gap: 20px;
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
main .sc-container-fluid .popular-cities h2 {
  font-size: calc(14px + var(--big-fs));
  line-height: 18px;
}
main .sc-container-fluid .popular-cities .p-city {
  display: flex;
  align-items: center;
}
main .sc-container-fluid .popular-cities .p-city a:hover {
  text-decoration: underline;
}
main .sc-container-fluid .share-your-location {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  margin: 40px auto;
}
main .sc-container-fluid .share-your-location p {
  color: #d61f79;
  white-space: break-spaces;
  font-size: calc(16px + var(--big-fs));
  line-height: 22px;
}
main .sc-container-fluid .main-listing-container {
  margin-top: 30px;
}
main .sc-container-fluid .new-escorts-container {
  display: grid;
  grid-gap: 20px;
}
main .sc-container-fluid .sc-border-title:not(:first-child) {
  margin-top: 30px;
}
main .sc-container-fluid #and6-stories .stories-container {
  margin-top: 30px;
  margin-bottom: 0;
  padding: 0 !important;
  border: none !important;
}
@media (max-width: 768px) {
  main .sc-container-fluid #and6-stories .stories-container {
    margin-bottom: 40px;
  }
}
main .sc-container-fluid .banner-group-mobile {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column: 1/-1;
  grid-gap: 10px;
}
main .sc-container-fluid .banner-group-mobile > *:not(.half) {
  grid-column: 1/-1;
}
@media (min-width: 481px) {
  main .sc-container-fluid .banner-group-mobile {
    display: none;
  }
}
